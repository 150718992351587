import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import _ from 'underscore'
import storage from 'redux-persist/lib/storage'

import { hideStickyBar, showStickyBar, Sort } from '../config/utils'

function calcSubsData(state, subscription, totalSubscriptionCount) {
  if (subscription?.length && totalSubscriptionCount?.length) {
    let total = 0
    let data = subscription
      .map(s => {
        const count = totalSubscriptionCount.find(c => c[0] === s.id)?.[1] || 0
        total += count
        return { ...s, count }
      })
      .map(s => ({ ...s, percentage: Math.floor((s.count * 100) / total) }))

    state.subscriptionCountData = data
  }
}

const initialState = {
  totalSubscriptionCount: [],
  sideMenuSelectedKey: '',
  sideMenuOpenKeys: [],

  loadingServices: false,
  servicesListenerAdded: false,
  services: [],

  languageDictionary: [],
  flatLanguageData: {},

  themecolor: '#1780CD',
  themefontColor: '#ffffff',

  currentLanguage: localStorage.getItem('language') || 'en',
  previousLanguage: 'en',
  userListListenerAdded: false,
  superAdminUsers: [],
  isGetLoggedUserListener: false,

  departments: [],
  subscription: [],
  subscriptionListenerAdded: false,

  hotelListenerAdded: false,
  hotels: [],
  totalHotelCount: 0,
  totalRoomCount: 0,
  totalCountryCount: 0,
  activeHotelCount: 0,
  activeHotelPercentage: 0,
  inActiveHotelPercentage: 0,
  subscriptionCountData: [],

  departmentsNew: [],
  departmentListenerAdded: false,
  servicesNew: {},
  loading: false,
  production: {
    enableStickyBar: false,
    showMaintenancePage: false,
    fromDate: null,
    toDate: null,
  },
  hotelGroups: {
    hotelGroupById: {},
    hotelGroupByName: {},
  },
  defaultLocationTypes: [],
  defaultOverallFeedbackQuestions: [],
  userInfo: null,
  appStatus: true,
  guestSettingsList: [],

  // Partner management related state
  partnerListenerAdded: false,
  partners: [],
  partnerCategories: [],
  partnerCountries: [],
  partnerRegions: [],
}

const slice = createSlice({
  name: 'InPlass Hotel Admin',
  initialState,
  reducers: {
    setAppStatus: (state, action) => {
      state.appStatus = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setDefaultOverallFeedbackQuestions: (state, action) => {
      state.defaultOverallFeedbackQuestions = action.payload
    },
    setDefaultLocationTypes: (state, action) => {
      state.defaultLocationTypes = action.payload
    },
    setUserListListenerStatus: (state, action) => {
      state.userListListenerAdded = action.payload
    },
    setProduction: (state, action) => {
      const data = { ...state.production, ...action.payload }
      state.production = data
      if (data.enableStickyBar) {
        showStickyBar()
      } else {
        hideStickyBar()
      }
    },
    setSuperAdminUser: (state, action) => {
      state.superAdminUsers = action.payload
    },
    setSideMenuSelectedKey: (state, action) => {
      state.sideMenuSelectedKey = action.payload
      if (Array.isArray(action.payload) && action.payload.includes('sub1'))
        state.sideMenuOpenKeys = ['sub1']
    },

    setisGetLoggedUserListener: (state, action) => {
      state.isGetLoggedUserListener = action.payload
    },
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload
      calcSubsData(state, action.payload, state.totalSubscriptionCount)
    },
    setSubscriptionListenerAdded: (state, action) => {
      state.subscriptionListenerAdded = action.payload
    },
    setServicesListenerAdded: state => {
      state.servicesListenerAdded = true
    },
    setLoadingServices: (state, action) => {
      state.loadingService = action.payload
    },
    setServices: (state, action) => {
      const sortedServies = Sort(action.payload, 'index')
      state.services = sortedServies
      state.loadingService = false
    },
    setHotels: (state, action) => {
      state.hotels = action.payload.hotels
      state.totalHotelCount = action.payload.totalHotelCount
      state.totalRoomCount = action.payload.totalRoomCount
      state.totalCountryCount = action.payload.totalCountryCount
      state.activeHotelCount = action.payload.activeHotelCount
      state.activeHotelPercentage = action.payload.activeHotelPercentage
      state.inActiveHotelPercentage = action.payload.inActiveHotelPercentage
      state.totalSubscriptionCount = action.payload.totalSubscriptionCount
      calcSubsData(
        state,
        state.subscription,
        action.payload.totalSubscriptionCount
      )
    },
    setHotelListenerAdded: (state, action) => {
      state.hotelListenerAdded = action.payload
    },
    setDepartmentListenerAdded: (state, action) => {
      state.departmentListenerAdded = action.payload
    },
    setDepartmentsNew: (state, action) => {
      state.departmentsNew = action.payload
    },
    setCustomDepartmentsNew: (state, action) => {
      state.customDepartmentsNew = action.payload.map((d, i) => ({
        ...d,
        index: i + 1,
      }))
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setServicesNew: (state, action) => {
      const clone = _.clone(state.servicesNew)
      clone[action.payload.id] = action.payload.data
      state.servicesNew = clone
    },
    setHotelGroups: (state, action) => {
      state.hotelGroups = action.payload
    },
    setGuestSettingsList: (state, action) => {
      state.guestSettingsList = action.payload
    },
    // Partner management related actions
    setPartnerListenerAdded: (state, action) => {
      state.partnerListenerAdded = action.payload
    },
    setPartners: (state, action) => {
      state.partners = action.payload
    },
    setPartnerCategories: (state, action) => {
      state.partnerCategories = action.payload
    },
    setPartnerCountries: (state, action) => {
      state.partnerCountries = action.payload
    },
    setPartnerRegions: (state, action) => {
      state.partnerRegions = action.payload
    },
  },
  extraReducers: {
    // for thunk events
  },
})

const persistConfig = {
  key: 'InPlass Hotel Admin',
  version: 1,
  storage,
  whitelist: ['sideMenuSelectedKey', 'sideMenuOpenKeys'],
}

const { reducer } = slice
const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const { actions } = slice
export const persistor = persistStore(store)
export default store
