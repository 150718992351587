import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Alert,
  Row,
  Col,
  Divider,
  message,
} from 'antd'
import { countryList } from '../../../config/countryList'
import stateList from '../../../config/stateList'
import { useSelector } from 'react-redux'

const { Option } = Select

const PartnerModal = ({
  visible,
  onCancel,
  onSubmit,
  initialValues = null,
  title = 'Add Partner',
  categories = [],
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [logoError, setLogoError] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [countrySpecificRegions, setCountrySpecificRegions] = useState({})
  const [isAllCountriesSelected, setIsAllCountriesSelected] = useState(false)
  const partners = useSelector(state => state.partners)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(null)

  // Default region options as fallback when no regions are found for selected countries
  const defaultRegions = ['All']

  useEffect(() => {
    if (visible) {
      form.resetFields()
      if (initialValues) {
        // Process initialValues *before* setting them to the form
        const processedValues = { ...initialValues }
        let initialImageUrl = null

        if (processedValues.image) {
          initialImageUrl = processedValues.image
          setFileList([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: processedValues.image,
            },
          ])
        } else {
          setFileList([])
        }

        let countries = []
        if (processedValues.country) {
          // Ensure country is always an array
          countries = Array.isArray(processedValues.country)
            ? processedValues.country
            : processedValues.country.split(', ')
          processedValues.country = countries // Update the value to be set on the form
        }

        // Set the processed values on the form
        form.setFieldsValue(processedValues)

        // Now handle state updates based on the processed countries array
        if (countries.length > 0) {
          if (countries.includes('All')) {
            setIsAllCountriesSelected(true)
            setSelectedCountries([]) // Clear selected countries state if 'All' is present
          } else {
            setIsAllCountriesSelected(false)
            setSelectedCountries(countries)
            updateCountrySpecificRegions(countries)

            // Handle region initialization (using original initialValues.region for logic)
            if (initialValues.region) {
              const regions = Array.isArray(initialValues.region)
                ? initialValues.region
                : initialValues.region.split(', ')

              // Initialize regions for each country
              const countryRegionsMap = {}

              countries.forEach(country => {
                // Update the countrySpecificRegions right away for this country
                const countryData = stateList.find(c => c.country === country)
                const countryRegions =
                  countryData && countryData.states.length > 0
                    ? [
                        { id: 'All', name: 'All' },
                        ...countryData.states.map(state => ({
                          id: state,
                          name: state,
                        })),
                      ]
                    : [
                        { id: 'All', name: 'All' },
                        ...defaultRegions.map(region => ({
                          id: region,
                          name: region,
                        })),
                      ]

                // Get regions that belong to this country
                const regionIds = regions.filter(region =>
                  countryRegions.some(cr => cr.id === region)
                )

                if (regionIds.length > 0) {
                  countryRegionsMap[country] = regionIds
                }
              })

              // Set all country regions at once
              if (Object.keys(countryRegionsMap).length > 0) {
                form.setFieldsValue({
                  regions: countryRegionsMap,
                })
              }
            }
          }
        }
      } else {
        // Set default values for new partners
        form.setFieldsValue({ status: 'Active' })
        setFileList([])
        setSelectedCountries([])
        setIsAllCountriesSelected(false)
        setCountrySpecificRegions({})
        setInitialFormValues(form.getFieldsValue(true))
      }
      setImageFile(null)
      setIsFormChanged(false)

      if (initialValues) {
        const processedInitial = form.getFieldsValue(true)
        const initialImage = initialValues?.image || null
        const relevantInitial = {
          title: processedInitial.title,
          categoryId: processedInitial.categoryId,
          country: processedInitial.country,
          regions: processedInitial.regions,
          affiliateLink: processedInitial.affiliateLink,
          status: processedInitial.status,
          isGlobal: processedInitial.isGlobal,
          image: initialImage,
        }
        setInitialFormValues(relevantInitial)
      } else {
        setInitialFormValues(null)
      }
    }
  }, [visible, initialValues, form])

  // Update country-specific regions when selected countries change
  const updateCountrySpecificRegions = countries => {
    const regions = {}

    countries.forEach(country => {
      const countryData = stateList.find(c => c.country === country)
      if (countryData && countryData.states.length > 0) {
        regions[country] = [
          { id: 'All', name: 'All' },
          ...countryData.states.map(state => ({ id: state, name: state })),
        ]
      } else {
        regions[country] = [
          { id: 'All', name: 'All' },
          ...defaultRegions.map(region => ({ id: region, name: region })),
        ]
      }
    })

    setCountrySpecificRegions(regions)
  }

  // Combine all selected regions from different countries
  useEffect(() => {
    if (
      !isAllCountriesSelected &&
      Object.keys(countrySpecificRegions).length > 0
    ) {
      // Get all regions currently selected in the form
      const allRegions = {}
      Object.keys(countrySpecificRegions).forEach(country => {
        const selectedRegions = form.getFieldValue(['regions', country])
        if (selectedRegions) {
          // Check if "All" is selected for this country
          if (selectedRegions.includes('All')) {
            // If "All" is selected, add all regions for this country
            countrySpecificRegions[country]
              .filter(region => region.id !== 'All')
              .forEach(region => {
                allRegions[region.id] = true
              })
          } else {
            // Add only selected regions
            selectedRegions.forEach(region => {
              allRegions[region] = true
            })
          }
        }
      })

      // Set the combined regions to the region field
      form.setFieldsValue({ region: Object.keys(allRegions) })
    }
  }, [countrySpecificRegions, form, isAllCountriesSelected])

  // Function to check for duplicate partners
  const checkDuplicatePartner = values => {
    if (!values.categoryId || !values.country || !values.region) {
      return false
    }

    // Convert values to arrays if they're not already
    const countries = Array.isArray(values.country)
      ? values.country
      : [values.country]
    const regions = Array.isArray(values.region)
      ? values.region
      : [values.region]

    // Check each country-region combination
    for (const country of countries) {
      for (const region of regions) {
        const isDuplicate = partners.some(partner => {
          // Skip the current partner if we're editing
          if (initialValues && partner.key === initialValues.key) {
            return false
          }

          // Check if partner belongs to the same category
          if (partner.categoryId !== values.categoryId) {
            return false
          }

          // Check if partner has the same country and region
          const partnerCountries = partner.country.split(', ')
          const partnerRegions = partner.region.split(', ')

          return (
            partnerCountries.includes(country) &&
            partnerRegions.includes(region)
          )
        })

        if (isDuplicate) {
          message.error(
            `A partner already exists for ${country} - ${region} in this category`
          )
          return true
        }
      }
    }

    return false
  }

  // Handle form submission
  const handleSubmit = async values => {
    try {
      setLoading(true)
      console.log('Form values:', values) // Add logging

      // Check if any validation issues need to be addressed
      if (!isAllCountriesSelected) {
        // For selected countries, ensure regions are selected for each country
        const missingRegions = selectedCountries.filter(country => {
          const regions = form.getFieldValue(['regions', country])
          return !regions || regions.length === 0
        })

        if (missingRegions.length > 0) {
          message.error('Please select regions for all selected countries')
          setLoading(false)
          return
        }

        // Collect all selected regions from each country
        const allRegions = []
        selectedCountries.forEach(country => {
          const selectedRegions = form.getFieldValue(['regions', country])
          if (selectedRegions) {
            // If 'All' is selected for a country, add all regions for that country
            if (selectedRegions.includes('All')) {
              const countryRegions = countrySpecificRegions[country] || []
              const regionNames = countryRegions
                .filter(region => region.id !== 'All')
                .map(region => region.id)
              allRegions.push(...regionNames)
            } else {
              // Add only selected regions
              allRegions.push(...selectedRegions)
            }
          }
        })

        // Set the combined regions to the region field
        values.region = allRegions
      }

      // Check for duplicate partners
      if (checkDuplicatePartner(values)) {
        return
      }

      // Ensure position is a number
      if (values.position) {
        values.position = parseInt(values.position)
      }

      // Set status to Active if not specified
      if (!values.status) {
        values.status = 'Active'
      }

      // Handle the "All" country selection
      if (isAllCountriesSelected) {
        values.country = ['All']
        values.region = ['All'] // When All countries are selected, set regions to All
        values.isGlobal = true // Automatically set isGlobal to true when All countries are selected
      }

      // Check for image upload if it's a new partner or if the image was removed
      if (fileList.length === 0 && (!initialValues || !initialValues.image)) {
        message.error('Please upload a partner logo')
        setLoading(false)
        return
      }

      const formData = {
        ...values,
        image:
          fileList.length > 0
            ? fileList[0].url || fileList[0].thumbUrl
            : initialValues?.image,
      }

      console.log('Submitting form data:', formData) // Add logging
      await onSubmit(formData, imageFile)
    } catch (error) {
      console.error('Validation or Submission failed:', error)
      message.error('Please check all required fields or submission failed.')
    } finally {
      setLoading(false)
    }
  }

  const validateProfileImage = file => {
    const allowedImageSizeMb = 1
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const lessThan1Mb = file.size / 1024 <= 1024 * allowedImageSizeMb

    if (!isJpgOrPng) {
      setLogoError('Invalid format, please upload JPEG or PNG')
    } else if (!lessThan1Mb) {
      setLogoError('Maximum upload size is 1mb')
    } else {
      setLogoError('')
    }

    if (isJpgOrPng && lessThan1Mb) {
      // Store the file object for Firebase upload
      setImageFile(file)

      const fileReader = new FileReader()
      fileReader.addEventListener('load', e => {
        setFileList([
          {
            uid: '-1',
            name: file.name,
            status: 'done',
            url: e.target.result,
          },
        ])
      })
      fileReader.readAsDataURL(file)
    }

    return false // Prevent default upload behavior
  }

  const clearProfileImage = () => {
    setFileList([])
    setImageFile(null)
  }

  const imageUrl =
    fileList.length > 0 ? fileList[0].url || fileList[0].thumbUrl : null

  // Handle country change
  const handleCountryChange = values => {
    // Check if "All" was selected or deselected
    if (values.includes('All')) {
      // If "All" is selected, clear other selections and set flag
      setIsAllCountriesSelected(true)
      setSelectedCountries([])
      form.setFieldsValue({
        country: ['All'],
        isGlobal: true, // Set isGlobal to true when All countries are selected
      })
    } else {
      // Normal selection - update the selected countries
      setIsAllCountriesSelected(false)
      setSelectedCountries(values)
      updateCountrySpecificRegions(values)
      form.setFieldsValue({ isGlobal: false }) // Set isGlobal to false when specific countries are selected
    }
  }

  // Generate region field for a specific country
  const renderRegionField = country => {
    const regions = countrySpecificRegions[country] || []

    return (
      <div className='form-group cmn-input' key={`region-${country}`}>
        <Form.Item
          name={['regions', country]}
          label={`${country} Regions`}
          rules={[
            { required: true, message: `Please select regions for ${country}` },
          ]}
        >
          <Select
            mode='multiple'
            placeholder={`Select regions for ${country}`}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={3}
            maxTagPlaceholder={omittedValues =>
              `+ ${omittedValues.length} more`
            }
          >
            {regions.map(region => (
              <Option key={region.id} value={region.id}>
                {region.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    )
  }

  // Function to handle form value changes and compare with initial state
  const handleValuesChange = (changedValues, allValues) => {
    if (initialValues && initialFormValues) {
      const currentImage = imageUrl
      const relevantCurrent = {
        title: allValues.title,
        categoryId: allValues.categoryId,
        country: allValues.country,
        regions: allValues.regions,
        affiliateLink: allValues.affiliateLink,
        status: allValues.status,
        isGlobal: allValues.isGlobal,
        image: currentImage,
      }

      if (
        JSON.stringify(relevantCurrent) !== JSON.stringify(initialFormValues)
      ) {
        setIsFormChanged(true)
      } else {
        setIsFormChanged(false)
      }
    } else {
      setIsFormChanged(true)
    }
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      className='addHotelmodal cmnModal'
      footer={null}
      width={800}
      centered
    >
      <div className='imageUpload-wrp'>
        <figure>
          <div className='upload-figin'>
            <img src={imageUrl ?? 'images/cam.png'} alt='Partner Logo' />
          </div>
          {imageUrl && (
            <button className='removebtn' onClick={clearProfileImage}>
              <img src='images/close.svg' alt='Remove' />
            </button>
          )}
        </figure>
        <div className='uploadbtn-wrp'>
          <Upload
            accept='.png, .jpeg, .jpg'
            beforeUpload={validateProfileImage}
            showUploadList={false}
          >
            <button>Upload Photo</button>
          </Upload>

          {logoError ? (
            <Alert message={logoError} type='error' showIcon />
          ) : null}
          <p>Image should be in PNG or JPEG file with maximum of size 1mb</p>
        </div>
      </div>

      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues || {}}
        onFinish={handleSubmit}
        validateTrigger={['onChange', 'onBlur']}
        onValuesChange={handleValuesChange}
      >
        <div className='row'>
          <div className='col-12 col-sm-6 col-md-4'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='title'
                label='Partner Title'
                rules={[
                  { required: true, message: 'Please enter partner title' },
                ]}
              >
                <Input placeholder='Enter partner title' maxLength={50} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-3'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='categoryId'
                label='Partner Category'
                rules={[
                  { required: true, message: 'Please select partner category' },
                ]}
              >
                <Select placeholder='Select partner category'>
                  {categories.map(category => (
                    <Option key={category.key} value={category.key}>
                      {category.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className='col-3 col-sm-3 col-md-5'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='country'
                label='Country'
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <Select
                  mode='multiple'
                  placeholder='Select countries'
                  showSearch={true}
                  onChange={handleCountryChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount={3}
                  maxTagPlaceholder={omittedValues =>
                    `+ ${omittedValues.length} more`
                  }
                >
                  <Option key='All' value='All'>
                    All Countries
                  </Option>
                  {countryList.map(country => (
                    <Option
                      key={country.name}
                      value={country.name}
                      disabled={isAllCountriesSelected}
                    >
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          {/* Hidden field to store combined regions */}
          <Form.Item
            name='region'
            hidden={true}
            rules={[
              {
                required: !isAllCountriesSelected,
                message: 'Please select regions',
                validator: (_, value) => {
                  if (isAllCountriesSelected) {
                    return Promise.resolve()
                  }

                  // For country-specific selection, check if we have any regions
                  if (!value || value.length === 0) {
                    // Check if we have regions in the country-specific fields
                    const hasAnyRegions = selectedCountries.some(country => {
                      const regions = form.getFieldValue(['regions', country])
                      return regions && regions.length > 0
                    })

                    if (hasAnyRegions) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject('Please select at least one region')
                    }
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input type='hidden' />
          </Form.Item>

          {/* Hidden field for isGlobal */}
          <Form.Item name='isGlobal' hidden={true}>
            <Input type='hidden' />
          </Form.Item>

          {/* Only show the region section if countries (not "All") are selected */}
          {!isAllCountriesSelected && selectedCountries.length > 0 && (
            <div className='col-12'>
              <Divider orientation='left'>Regions</Divider>
              <Row gutter={16}>
                {selectedCountries.map(country => (
                  <Col key={country} span={8}>
                    {renderRegionField(country)}
                  </Col>
                ))}
              </Row>
            </div>
          )}

          <div className='col-12 col-sm-6 col-md-4'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='affiliateLink'
                label='Affiliate Link'
                rules={[
                  { required: true, message: 'Please enter affiliate link' },
                  { type: 'url', message: 'Please enter a valid URL' },
                ]}
              >
                <Input placeholder='https://example.com' />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className='modalFooter'>
          <Button
            key='back'
            onClick={onCancel}
            className='grayBtn'
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            key='submit'
            type='primary'
            htmlType='submit'
            loading={loading}
            className='blueBtn ml-3 ml-lg-4'
            disabled={loading || (initialValues && !isFormChanged)}
          >
            {initialValues ? 'Update' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default PartnerModal
