import React, { useEffect, useState } from 'react'
import { Tabs, Table, Input, Select, Button, message } from 'antd'

import Header from '../../Common/Header/Header'
import SideMenu from '../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../Common/PageNameCard/Pagenamecard'
import DeleteModal from '../../Common/Modals/DeleteModal'

import { GetHotelListener } from '../../../services/hotel'
import { useDispatch, useSelector } from 'react-redux'
import { AddSubscriptionListener } from '../../../services/subscription'
import {
  GetPartnerListener,
  GetPartners,
  GetPartnerCategories,
  AddPartner,
  UpdatePartner,
  DeletePartner,
  AddPartnerCategory,
  UpdatePartnerCategory,
  DeletePartnerCategory,
} from '../../../services/partner'
import PartnerModal from './PartnerModal'
import CategoryModal from './CategoryModal'
import { countryList } from '../../../config/countryList'
import stateList from '../../../config/stateList'

const { TabPane } = Tabs
const { Search } = Input
const { Option } = Select

const statusList = [
  { id: 'all', name: 'All' },
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
]

// Default region list as fallback
const defaultRegionList = [
  { id: 'all', name: 'All' },
  { id: 'North', name: 'North' },
  { id: 'South', name: 'South' },
  { id: 'East', name: 'East' },
  { id: 'West', name: 'West' },
  { id: 'Kerala', name: 'Kerala' },
  { id: 'Tamil Nadu', name: 'Tamil Nadu' },
  { id: 'Abu Dhabi', name: 'Abu Dhabi' },
]

const Partner = () => {
  const {
    hotelListenerAdded,
    subscriptionListenerAdded,
    partnerListenerAdded,
    partners,
    partnerCategories,
  } = useSelector(state => state)

  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('1')
  const [partnerData, setPartnerData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [countryFilterList, setCountryFilterList] = useState([
    { id: 'all', name: 'All' },
  ])
  const [regionFilterList, setRegionFilterList] = useState([
    { id: 'all', name: 'All' },
  ])
  const [partnerModalVisible, setPartnerModalVisible] = useState(false)
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  const [editingPartner, setEditingPartner] = useState(null)
  const [editingCategory, setEditingCategory] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [countryFilter, setCountryFilter] = useState('all')
  const [regionFilter, setRegionFilter] = useState('all')
  const [statusFilter, setStatusFilter] = useState('all')
  const [loading, setLoading] = useState(true)
  const [selectedCountryForRegions, setSelectedCountryForRegions] =
    useState(null)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [partnerToDelete, setPartnerToDelete] = useState(null)
  const [showCategoryDeleteConfirmation, setShowCategoryDeleteConfirmation] =
    useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState(null)

  useEffect(() => {
    setLoading(true)
    AddSubscriptionListener(dispatch, subscriptionListenerAdded)
    GetHotelListener(dispatch, hotelListenerAdded)
    GetPartnerListener(dispatch, partnerListenerAdded)
  }, [
    dispatch,
    hotelListenerAdded,
    subscriptionListenerAdded,
    partnerListenerAdded,
  ])

  useEffect(() => {
    if (partners && partners.length > 0) {
      console.log('Partners data received:', partners.length)
      setPartnerData(partners)
      setLoading(false)
    } else if (partners) {
      setPartnerData([])
      setLoading(false)
    }
  }, [partners])

  useEffect(() => {
    if (partnerCategories && partnerCategories.length > 0) {
      console.log('Categories data received:', partnerCategories.length)
      const formattedCategories = partnerCategories.map(category => ({
        key: category.id || category.key,
        id: category.id || category.key,
        title: category.title,
        position: category.position,
        status: category.status,
        image: category.image,
      }))
      setCategoryData(formattedCategories)
      setLoading(false)
    } else if (partnerCategories) {
      setCategoryData([])
      setLoading(false)
    }
  }, [partnerCategories])

  // Initialize country filter list from countryList
  useEffect(() => {
    // Create a list with 'All' option and all countries from the config
    const formattedCountryList = [
      { id: 'all', name: 'All' },
      ...countryList.map(country => ({ id: country.name, name: country.name })),
    ]
    setCountryFilterList(formattedCountryList)
  }, [])

  // Update region filter list when selected country changes
  useEffect(() => {
    if (selectedCountryForRegions && selectedCountryForRegions !== 'all') {
      const countryData = stateList.find(
        c => c.country === selectedCountryForRegions
      )
      if (countryData && countryData.states.length > 0) {
        const formattedRegionList = [
          { id: 'all', name: 'All' },
          ...countryData.states.map(state => ({ id: state, name: state })),
        ]
        setRegionFilterList(formattedRegionList)
      } else {
        setRegionFilterList(defaultRegionList)
      }
    } else {
      // If no country is selected or 'All' is selected, show all regions from all countries
      let allRegions = []
      stateList.forEach(country => {
        if (country.states && country.states.length > 0) {
          allRegions = [...allRegions, ...country.states]
        }
      })

      // Remove duplicates
      if (allRegions.length > 0) {
        const uniqueRegions = [...new Set(allRegions)]
        const formattedRegionList = [
          { id: 'all', name: 'All' },
          ...uniqueRegions.map(region => ({ id: region, name: region })),
        ]
        setRegionFilterList(formattedRegionList)
      } else {
        setRegionFilterList(defaultRegionList)
      }
    }
  }, [selectedCountryForRegions])

  // Partner tab columns
  const partnerColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, row) => (
        <div className='tableuser sa-tableuser'>
          <figure>
            <img
              className='img-fluid'
              src={row.image || 'images/logo.svg'}
              alt={title}
              style={{ width: '30px', height: '30px' }}
            />
          </figure>
          <span>{title}</span>
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          bordered={false}
          onChange={value =>
            handlePartnerStatusChange(
              record.key,
              record.categoryId,
              value,
              record
            )
          }
          className={status === 'Active' ? 'activeStatus' : 'inactiveStatus'}
        >
          <Option value='Active'>Active</Option>
          <Option value='Inactive'>Inactive</Option>
        </Select>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (_, record) => (
        <div className='tableactionbtn-grp'>
          <button onClick={() => handleEditPartner(record)}>
            <img src='/images/sa-tedit.svg' alt='Edit' />
          </button>
          <button
            onClick={() => handleDeletePartner(record.key, record.categoryId)}
          >
            <img src='/images/sa-tdelete.svg' alt='Delete' />
          </button>
        </div>
      ),
    },
  ]

  // Category tab columns
  const categoryColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, row) => (
        <div className='tableuser sa-tableuser'>
          <figure>
            <img
              className='img-fluid'
              src={row.image || 'images/hotelLogo.svg'}
              alt={title}
              style={{ width: '30px', height: '30px' }}
            />
          </figure>
          <span>{title}</span>
        </div>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          bordered={false}
          onChange={value =>
            handleCategoryStatusChange(record.id || record.key, value, record)
          }
          className={status === 'Active' ? 'activeStatus' : 'inactiveStatus'}
        >
          <Option value='Active'>Active</Option>
          <Option value='Inactive'>Inactive</Option>
        </Select>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (_, record) => (
        <div className='tableactionbtn-grp'>
          <button onClick={() => handleEditCategory(record)}>
            <img src='/images/sa-tedit.svg' alt='Edit' />
          </button>
          <button onClick={() => handleDeleteCategory(record.id || record.key)}>
            <img src='/images/sa-tdelete.svg' alt='Delete' />
          </button>
        </div>
      ),
    },
  ]

  const handleTabChange = key => {
    setActiveTab(key)
  }

  const handleAddPartner = () => {
    setEditingPartner(null)
    setPartnerModalVisible(true)
  }

  const handleEditPartner = partner => {
    setEditingPartner(partner)
    setPartnerModalVisible(true)
  }

  const handleDeletePartner = async (key, categoryId) => {
    setPartnerToDelete({ key, categoryId })
    setShowDeleteConfirmation(true)
  }

  const confirmDeletePartner = async () => {
    try {
      setLoading(true)
      const result = await DeletePartner(
        partnerToDelete.key,
        partnerToDelete.categoryId
      )
      if (result.success) {
        message.success('Partner deleted successfully')
        // Only refresh partner data - no need to update categories
        await refreshPartnerData()
      } else {
        message.error(
          'Failed to delete partner: ' + (result.error || 'Unknown error')
        )
      }
      setLoading(false)
      setShowDeleteConfirmation(false)
      setPartnerToDelete(null)
    } catch (error) {
      console.error('Error deleting partner:', error)
      message.error('An error occurred while deleting the partner')
      setLoading(false)
      setShowDeleteConfirmation(false)
      setPartnerToDelete(null)
    }
  }

  // Add this function to manually refresh partner data
  const refreshPartnerData = async () => {
    try {
      setLoading(true)
      const result = await GetPartners()
      if (result && result.partners) {
        setPartnerData(result.partners)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error refreshing partner data:', error)
      setLoading(false)
    }
  }

  // Add this function to manually refresh category data
  const refreshCategoryData = async () => {
    try {
      setLoading(true)
      const categories = await GetPartnerCategories()
      if (categories && categories.length > 0) {
        const formattedCategories = categories.map(category => ({
          key: category.id || category.key,
          id: category.id || category.key,
          title: category.title,
          position: category.position,
          status: category.status,
          image: category.image,
        }))
        setCategoryData(formattedCategories)
      } else {
        setCategoryData([])
      }
      setLoading(false)
    } catch (error) {
      console.error('Error refreshing category data:', error)
      setLoading(false)
    }
  }

  const handlePartnerSubmit = async (values, imageFile) => {
    try {
      setLoading(true)
      if (editingPartner) {
        // Update existing partner

        // Format country and region data properly
        let countryData = values.country
        let regionData = values.region

        // Handle global partners
        if (values.isGlobal) {
          countryData = ['All']
          regionData = ['All']
        } else if (
          typeof countryData === 'string' &&
          countryData.includes(', ')
        ) {
          // Handle comma-separated strings
          countryData = countryData.split(', ')
          regionData =
            typeof regionData === 'string' ? regionData.split(', ') : regionData
        }

        console.log('Updating partner with formatted data:', {
          key: editingPartner.key,
          categoryId: values.categoryId,
          oldCategoryId: editingPartner.categoryId,
          countryData,
          regionData,
        })

        // Ensure we're passing the partner ID in the right format
        const partnerId =
          editingPartner.categoryId === values.categoryId
            ? editingPartner.key
            : `${editingPartner.categoryId}/${editingPartner.key}`

        const result = await UpdatePartner(
          partnerId,
          {
            ...values,
            categoryId: values.categoryId,
            country: countryData,
            region: regionData,
          },
          imageFile
        )

        if (result.success) {
          message.success('Partner updated successfully')
          // Refresh data to show the updated partner
          await refreshPartnerData()
        } else {
          message.error(
            'Failed to update partner: ' + (result.error || 'Unknown error')
          )
        }
      } else {
        // Add new partner
        // For new partners, we need to select a category
        if (!values.categoryId) {
          message.error('Please select a category')
          setLoading(false)
          return
        }

        // Format country and region data properly
        let countryData = values.country
        let regionData = values.region

        // Handle global partners
        if (values.isGlobal) {
          countryData = ['All']
          regionData = ['All']
        }

        const result = await AddPartner(
          {
            ...values,
            country: countryData,
            region: regionData,
          },
          imageFile
        )

        if (result.success) {
          message.success('Partner added successfully')
          // Refresh data to show the new partner
          await refreshPartnerData()
        } else {
          message.error(
            'Failed to add partner: ' + (result.error || 'Unknown error')
          )
        }
      }
      setPartnerModalVisible(false)
      setLoading(false)
    } catch (error) {
      console.error('Error submitting partner:', error)
      message.error('An error occurred while saving the partner')
      setLoading(false)
    }
  }

  const handleAddCategory = () => {
    setEditingCategory(null)
    setCategoryModalVisible(true)
  }

  const handleEditCategory = category => {
    // Ensure the category has an id property
    if (category && !category.id && category.key) {
      category.id = category.key
    }
    setEditingCategory(category)
    setCategoryModalVisible(true)
  }

  const handleDeleteCategory = async categoryId => {
    setCategoryToDelete(categoryId)
    setShowCategoryDeleteConfirmation(true)
  }

  const confirmDeleteCategory = async () => {
    try {
      setLoading(true)
      // Log the categoryId to help with debugging
      console.log('Deleting category with ID:', categoryToDelete)

      if (!categoryToDelete) {
        message.error('Category ID is missing')
        setLoading(false)
        return
      }

      const result = await DeletePartnerCategory(categoryToDelete)
      if (result.success) {
        message.success('Category deleted successfully')
        // Refresh both partner and category data
        await refreshCategoryData()
        await refreshPartnerData()
      } else {
        message.error(
          'Failed to delete category: ' + (result.error || 'Unknown error')
        )
      }
      setLoading(false)
      setShowCategoryDeleteConfirmation(false)
      setCategoryToDelete(null)
    } catch (error) {
      console.error('Error deleting category:', error)
      message.error('An error occurred while deleting the category')
      setLoading(false)
      setShowCategoryDeleteConfirmation(false)
      setCategoryToDelete(null)
    }
  }

  const handleCategorySubmit = async (values, imageFile) => {
    try {
      setLoading(true)
      if (editingCategory) {
        // Ensure we have a valid category ID
        const categoryId = editingCategory.id || editingCategory.key

        if (!categoryId) {
          message.error('Category ID is missing')
          setLoading(false)
          return
        }

        console.log('Updating category with ID:', categoryId)

        // Update existing category using the dedicated function
        const result = await UpdatePartnerCategory(
          categoryId,
          values,
          imageFile
        )
        if (result.success) {
          message.success('Category updated successfully')
          // Refresh both partner and category data
          await refreshCategoryData()
          await refreshPartnerData()
        } else {
          message.error(
            'Failed to update category: ' + (result.error || 'Unknown error')
          )
        }
      } else {
        // Add new category using the dedicated function
        const result = await AddPartnerCategory(values, imageFile)
        if (result.success) {
          message.success('Category added successfully')
          // Refresh both partner and category data
          await refreshCategoryData()
          await refreshPartnerData()
        } else {
          message.error(
            'Failed to add category: ' + (result.error || 'Unknown error')
          )
        }
      }
      setCategoryModalVisible(false)
      setLoading(false)
    } catch (error) {
      console.error('Error submitting category:', error)
      message.error('An error occurred while saving the category')
      setLoading(false)
    }
  }

  const handleCountryChange = value => {
    setCountryFilter(value)
    setSelectedCountryForRegions(value)
    // Reset region filter when country changes
    setRegionFilter('all')
  }

  const handleRegionChange = value => {
    setRegionFilter(value)
  }

  const handleStatusChange = value => {
    setStatusFilter(value)
  }

  const handlePartnerStatusChange = async (
    partnerId,
    categoryId,
    newStatus,
    record
  ) => {
    try {
      setLoading(true)
      console.log('Updating partner status:', partnerId, categoryId, newStatus)

      // Ensure we're not passing a partnerId that already includes the categoryId
      const cleanPartnerId = partnerId.includes('/')
        ? partnerId
        : `${categoryId}/${partnerId}`

      // Prepare proper country and region data
      let countryData = record.country
      let regionData = record.region

      // Check if this is a global partner with "All" country
      if (record.isGlobal) {
        countryData = ['All']
        regionData = ['All']
      } else if (
        typeof countryData === 'string' &&
        countryData.includes(', ')
      ) {
        // Handle comma-separated strings
        countryData = countryData.split(', ')
        regionData =
          typeof regionData === 'string' ? regionData.split(', ') : regionData
      }

      console.log('Partner data for update:', {
        countryData,
        regionData,
        title: record.title,
        isGlobal: record.isGlobal,
      })

      const result = await UpdatePartner(cleanPartnerId, {
        categoryId: categoryId, // This is important to specify explicitly
        status: newStatus,
        title: record.title,
        position: record.position,
        image: record.image,
        affiliateLink: record.affiliateLink,
        isGlobal: record.isGlobal,
        country: countryData,
        region: regionData,
      })

      if (result.success) {
        message.success(`Partner status updated to ${newStatus}`)
        // Only refresh partner data - no need to update categories
        await refreshPartnerData()
      } else {
        message.error(
          `Failed to update status: ${result.error || 'Unknown error'}`
        )
      }
      setLoading(false)
    } catch (error) {
      console.error('Error updating partner status:', error)
      message.error('An error occurred while updating the status')
      setLoading(false)
    }
  }

  const handleCategoryStatusChange = async (categoryId, newStatus, record) => {
    try {
      setLoading(true)
      console.log('Updating category status with ID:', categoryId)

      if (!categoryId) {
        message.error('Category ID is missing')
        setLoading(false)
        return
      }

      const result = await UpdatePartnerCategory(categoryId, {
        title: record.title,
        position: record.position,
        status: newStatus,
        image: record.image,
      })

      if (result.success) {
        message.success(`Category status updated to ${newStatus}`)
        // Refresh category data
        await refreshCategoryData()
      } else {
        message.error(
          `Failed to update status: ${result.error || 'Unknown error'}`
        )
      }
      setLoading(false)
    } catch (error) {
      console.error('Error updating category status:', error)
      message.error('An error occurred while updating the status')
      setLoading(false)
    }
  }

  const resetFilter = () => {
    setSearchText('')
    setCountryFilter('all')
    setRegionFilter('all')
    setStatusFilter('all')
  }

  // Filter partner data based on search and filters
  const filteredPartnerData = partnerData.filter(item => {
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchText.toLowerCase())
    const matchesCountry =
      countryFilter === 'all' || item.country.includes(countryFilter)
    const matchesRegion =
      regionFilter === 'all' || item.region.includes(regionFilter)
    const matchesStatus = statusFilter === 'all' || item.status === statusFilter

    return matchesSearch && matchesCountry && matchesRegion && matchesStatus
  })

  // Filter category data based on search
  const filteredCategoryData = categoryData.filter(item =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  )

  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent hotelmgmt-wrp'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Partner Management'
                breadcrumb={['Super Admin', 'Partner']}
              />
            </div>
            <div className='col-12'>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab='Partner List' key='1'>
                  <div className='tablefilter-wrp'>
                    <div className='form-row'>
                      <div className='col-4 col-xl col-xl-3 mb-2'>
                        <div className='searchbox'>
                          <Search
                            placeholder='Search by Partner Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-4 col-xl'>
                        <div className='cmnSelect-form'>
                          <Select
                            showSearch={true}
                            value={countryFilter}
                            onChange={handleCountryChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {countryFilterList.map(country => (
                              <Option value={country.id} key={country.id}>
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='col-4 col-xl'>
                        <div className='cmnSelect-form'>
                          <Select
                            showSearch={true}
                            value={regionFilter}
                            onChange={handleRegionChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {regionFilterList.map(region => (
                              <Option value={region.id} key={region.id}>
                                {region.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='col-4 col-xl'>
                        <div className='cmnSelect-form'>
                          <Select
                            value={statusFilter}
                            onChange={handleStatusChange}
                          >
                            {statusList.map(status => (
                              <Option value={status.id} key={status.id}>
                                {status.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='col-4 col-md-auto'>
                        <Button
                          type='primary'
                          className='adduserbtn'
                          onClick={resetFilter}
                          title='Reset filters'
                        >
                          <img src='images/sa-clearicon.svg' alt='Clear' />
                        </Button>
                      </div>
                      <div className='col-4 col-md-auto ml-auto'>
                        <Button className='cmnBtn' onClick={handleAddPartner}>
                          Add Partner
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-wrp'>
                    <Table
                      columns={partnerColumns}
                      dataSource={filteredPartnerData}
                      pagination={{ pageSize: 10 }}
                      scroll={{ y: 496 }}
                      rowKey='key'
                      loading={loading}
                    />
                  </div>
                </TabPane>
                <TabPane tab='Partner Category' key='2'>
                  <div className='tablefilter-wrp'>
                    <div className='form-row'>
                      <div className='col-4 col-xl col-xl-3 mb-2'>
                        <div className='searchbox'>
                          <Search
                            placeholder='Search by Category Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-4 col-xl col-xl-3 mb-2'>
                        <div className='cmnSelect-form'>
                          <Select
                            value={statusFilter}
                            onChange={handleStatusChange}
                          >
                            {statusList.map(status => (
                              <Option value={status.id} key={status.id}>
                                {status.name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className='col-4 col-md-auto'>
                        <Button
                          type='primary'
                          className='adduserbtn'
                          onClick={resetFilter}
                          title='Reset filters'
                        >
                          <img src='images/sa-clearicon.svg' alt='Clear' />
                        </Button>
                      </div>
                      <div className='col-4 col-md-auto ml-auto'>
                        <Button className='cmnBtn' onClick={handleAddCategory}>
                          Add Category
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-wrp'>
                    <Table
                      columns={categoryColumns}
                      dataSource={filteredCategoryData}
                      pagination={{ pageSize: 10 }}
                      scroll={{ y: 496 }}
                      rowKey='key'
                      loading={loading}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      {/* Partner Modal */}
      <PartnerModal
        visible={partnerModalVisible}
        onCancel={() => setPartnerModalVisible(false)}
        onSubmit={handlePartnerSubmit}
        initialValues={editingPartner}
        title={editingPartner ? 'Edit Partner' : 'Add Partner'}
        categories={categoryData}
      />

      {/* Category Modal */}
      <CategoryModal
        visible={categoryModalVisible}
        onCancel={() => setCategoryModalVisible(false)}
        onSubmit={handleCategorySubmit}
        initialValues={editingCategory}
        title={editingCategory ? 'Edit Category' : 'Add Category'}
      />

      {/* Partner Delete Confirmation Modal */}
      <DeleteModal
        onOk={confirmDeletePartner}
        onCancel={() => {
          setShowDeleteConfirmation(false)
          setPartnerToDelete(null)
        }}
        visible={showDeleteConfirmation}
        title='Confirm Delete'
        message='Are you sure you want to delete this partner?'
      />

      {/* Category Delete Confirmation Modal */}
      <DeleteModal
        onOk={confirmDeleteCategory}
        onCancel={() => {
          setShowCategoryDeleteConfirmation(false)
          setCategoryToDelete(null)
        }}
        visible={showCategoryDeleteConfirmation}
        title='Confirm Delete'
        message='Are you sure you want to delete this category?'
      />
    </>
  )
}

export default Partner
