import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Upload, Button, Alert, message } from 'antd'
import { useSelector } from 'react-redux'

const CategoryModal = ({
  visible,
  onCancel,
  onSubmit,
  initialValues = null,
  title = 'Add Category',
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [logoError, setLogoError] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const partnerCategories = useSelector(state => state.partnerCategories)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(null)

  useEffect(() => {
    if (visible) {
      form.resetFields()
      let initialImageUrl = null
      if (initialValues) {
        form.setFieldsValue(initialValues)
        if (initialValues.image) {
          initialImageUrl = initialValues.image
          setFileList([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: initialValues.image,
            },
          ])
        } else {
          setFileList([])
        }
        setInitialFormValues({
          title: initialValues.title,
          position: initialValues.position,
          image: initialImageUrl,
        })
      } else {
        form.setFieldsValue({ status: 'Active' })
        setFileList([])
        setInitialFormValues(null)
      }
      setImageFile(null)
      setIsFormChanged(false)
    }
  }, [visible, initialValues, form])

  const checkDuplicatePosition = position => {
    if (position === null || position === undefined || position === '')
      return false

    const positionNum = parseInt(position)
    if (isNaN(positionNum)) return false

    return partnerCategories.some(category => {
      if (initialValues && category.key === initialValues.key) {
        return false
      }
      return (
        category.status === 'Active' &&
        typeof category.position === 'number' &&
        category.position === positionNum
      )
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (
        values.position !== null &&
        values.position !== undefined &&
        values.position !== ''
      ) {
        values.position = parseInt(values.position)
        if (isNaN(values.position)) {
          message.error('Position must be a valid number.')
          setLoading(false)
          return
        }
      } else {
        if (
          values.position === null ||
          values.position === undefined ||
          values.position === ''
        ) {
          message.error('Position is required.')
          setLoading(false)
          return
        }
      }

      if (checkDuplicatePosition(values.position)) {
        message.error(
          'This position is already in use by another active category'
        )
        setLoading(false)
        return
      }

      const currentImageUrl =
        fileList.length > 0 ? fileList[0].url || fileList[0].thumbUrl : null
      const finalImageUrl = currentImageUrl ?? initialValues?.image

      if (!finalImageUrl) {
        const errorMsg = 'Please upload a category image'
        setLogoError(errorMsg)
        message.error(errorMsg)
        setLoading(false)
        return
      } else {
        setLogoError('')
      }

      if (!values.status) {
        values.status = 'Active'
      }

      const formData = {
        ...values,
        image: finalImageUrl,
      }

      await onSubmit(formData, imageFile)
    } catch (errorInfo) {
      console.error('Validation or Submission failed:', errorInfo)
      if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
        // message.error('Please check the highlighted fields.')
      } else {
        // message.error('Failed to submit category. Please try again.')
      }
    } finally {
      setLoading(false)
    }
  }

  const validateProfileImage = file => {
    const allowedImageSizeMb = 1
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const lessThan1Mb = file.size / 1024 <= 1024 * allowedImageSizeMb

    if (!isJpgOrPng) {
      setLogoError('Invalid format, please upload JPEG or PNG')
    } else if (!lessThan1Mb) {
      setLogoError('Maximum upload size is 1mb')
    } else {
      setLogoError('')
    }

    if (isJpgOrPng && lessThan1Mb) {
      setImageFile(file)

      const fileReader = new FileReader()
      fileReader.addEventListener('load', e => {
        setFileList([
          {
            uid: '-1',
            name: file.name,
            status: 'done',
            url: e.target.result,
          },
        ])
        handleValuesChange({}, form.getFieldsValue(true))
      })
      fileReader.readAsDataURL(file)
    }

    return false
  }

  const clearProfileImage = () => {
    setFileList([])
    setImageFile(null)
    handleValuesChange({}, form.getFieldsValue(true))
  }

  const handleValuesChange = (changedValues, allValues) => {
    if (initialValues && initialFormValues) {
      const currentImageUrl =
        fileList.length > 0 ? fileList[0].url || fileList[0].thumbUrl : null
      const titleChanged = allValues.title !== initialFormValues.title
      const currentPosition =
        allValues.position !== '' &&
        allValues.position !== null &&
        allValues.position !== undefined
          ? parseInt(allValues.position)
          : null
      const initialPosition =
        initialFormValues.position !== null &&
        initialFormValues.position !== undefined
          ? parseInt(initialFormValues.position)
          : null
      const positionChanged = currentPosition !== initialPosition
      const imageChanged = currentImageUrl !== initialFormValues.image

      if (titleChanged || positionChanged || imageChanged) {
        setIsFormChanged(true)
      } else {
        setIsFormChanged(false)
      }
    } else {
      setIsFormChanged(true)
    }
  }

  const imageUrl =
    fileList.length > 0 ? fileList[0].url || fileList[0].thumbUrl : null

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      className='addHotelmodal cmnModal'
      footer={null}
      width={800}
      centered
    >
      <div className='imageUpload-wrp'>
        <figure>
          <div className='upload-figin'>
            <img
              src={imageUrl ?? initialValues?.image ?? 'images/cam.png'}
              alt='Category Logo'
            />
          </div>
          {(imageUrl || initialValues?.image) && (
            <button
              type='button'
              className='removebtn'
              onClick={clearProfileImage}
            >
              <img src='images/close.svg' alt='Remove' />
            </button>
          )}
        </figure>
        <div className='uploadbtn-wrp'>
          <Upload
            accept='.png, .jpeg, .jpg'
            beforeUpload={validateProfileImage}
            showUploadList={false}
            fileList={fileList}
          >
            <button type='button'>Upload Photo</button>
          </Upload>

          {logoError && <Alert message={logoError} type='error' showIcon />}
          <p>Image should be in PNG or JPEG file with maximum of size 1mb</p>
        </div>
      </div>

      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        validateTrigger={['onChange', 'onBlur']}
        onValuesChange={handleValuesChange}
      >
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='title'
                label='Category Title'
                rules={[
                  { required: true, message: 'Please enter category title' },
                ]}
              >
                <Input placeholder='Enter category title' maxLength={50} />
              </Form.Item>
            </div>
          </div>

          <div className='col-12 col-sm-6'>
            <div className='form-group cmn-input'>
              <Form.Item
                name='position'
                label='Position'
                rules={[
                  { required: true, message: 'Please enter position' },
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter a valid number',
                  },
                ]}
              >
                <Input
                  type='number'
                  placeholder='Enter position number'
                  min={1}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className='modalFooter'>
          <Button
            className='grayBtn'
            key='back'
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className='blueBtn ml-3 ml-lg-4'
            key='submit'
            htmlType='submit'
            loading={loading}
            disabled={loading || (!!initialValues && !isFormChanged)}
          >
            {initialValues ? 'Update' : 'Submit'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CategoryModal
