import { db } from '../config/firebase'
import { actions } from '../app/store'
import { UploadFile, DeleteFile } from '../services/common'

// Function to fetch partner categories from Firebase
export const GetPartnerCategories = async () => {
  let categories = []
  try {
    const categoriesSnapshot = await db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .get()

    if (categoriesSnapshot) {
      console.log('Partner Categories Count: ', categoriesSnapshot.size)
      for (const category of categoriesSnapshot.docs) {
        categories.push({
          key: category.id,
          ...category.data(),
          // Ensure consistent property names with the UI
          title: category.data().title,
          position: category.data().position,
          status: category.data().status === 'active' ? 'Active' : 'Inactive',
          image: category.data().imageUrl,
        })
      }
    }
  } catch (error) {
    console.log('Error fetching partner categories:', error)
  }
  return categories
}

// Function to fetch all partners from Firebase
export const GetPartners = async () => {
  let partners = []
  let uniqueCountries = new Set()
  let uniqueRegions = new Set()

  try {
    // First get all categories
    const categoriesSnapshot = await db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .get()

    if (categoriesSnapshot) {
      // For each category, fetch its partners
      for (const category of categoriesSnapshot.docs) {
        const categoryData = category.data()
        const categoryId = category.id
        const categoryTitle = categoryData.title || 'Unknown Category'

        // Fetch partners for this category
        const partnersSnapshot = await db
          .collection('partnersManagement')
          .doc('root')
          .collection('categories')
          .doc(categoryId)
          .collection('partnersList')
          .get()

        if (partnersSnapshot && partnersSnapshot.size > 0) {
          console.log(
            `Found ${partnersSnapshot.size} partners for category ${categoryTitle}`
          )

          // Add each partner to the partners array
          for (const partner of partnersSnapshot.docs) {
            const partnerData = partner.data()

            // Extract country and region information
            let countries = []
            let regions = []

            // Check if geography exists and contains countries/regions
            if (partnerData.geography) {
              countries = partnerData.geography.countries || []
              regions = partnerData.geography.regions || []
              console.log(`Partner ${partner.id} regions:`, regions)
            }

            // Add to unique sets
            countries.forEach(country => uniqueCountries.add(country))
            regions.forEach(region => uniqueRegions.add(region))

            partners.push({
              key: partner.id,
              title: partnerData.title || 'Unnamed Partner',
              category: categoryTitle,
              categoryId: categoryId,
              country: countries.length > 0 ? countries.join(', ') : 'N/A',
              region: regions.length > 0 ? regions.join(', ') : 'N/A',
              position: partnerData.position || 0,
              status: partnerData.status === 'active' ? 'Active' : 'Inactive',
              image: partnerData.imageUrl || '',
              affiliateLink: partnerData.affiliateLink || '',
              isGlobal: partnerData.isGlobal || false,
              metadata: partnerData.metadata || {},
              createdAt: partnerData.createdAt,
              updatedAt: partnerData.updatedAt,
            })
          }
        }
      }
    }

    // Convert sets to arrays and sort
    const countries = Array.from(uniqueCountries).sort()
    const regions = Array.from(uniqueRegions).sort()

    return {
      partners,
      countries,
      regions,
    }
  } catch (error) {
    console.log('Error fetching partners:', error)
    return {
      partners: [],
      countries: [],
      regions: [],
    }
  }
}

// Function to add a listener for partner data
export const GetPartnerListener = (dispatch, partnerListenerAdded) => {
  if (!partnerListenerAdded) {
    // Array to store all unsubscribe functions for cleanup
    let unsubscribeFunctions = []

    // First, get all categories with a listener
    const categoriesUnsubscribe = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .onSnapshot(
        categoriesSnapshot => {
          let categories = []

          // Process categories
          categoriesSnapshot.docs.forEach(category => {
            const categoryData = category.data()
            const categoryId = category.id

            // Add to categories array
            if (categoryData.title) {
              categories.push({
                key: categoryId,
                title: categoryData.title,
                position: categoryData.position,
                status:
                  categoryData.status === 'active' ? 'Active' : 'Inactive',
                image: categoryData.imageUrl,
              })
            }
          })

          // Update categories in Redux
          dispatch(actions.setPartnerCategories(categories))

          // Clean up previous partner listeners
          unsubscribeFunctions.forEach(unsub => {
            if (typeof unsub === 'function') {
              unsub()
            }
          })
          unsubscribeFunctions = []

          // Add listener for partners in each category
          categoriesSnapshot.docs.forEach(category => {
            const categoryId = category.id
            const categoryData = category.data()

            const partnerUnsubscribe = db
              .collection('partnersManagement')
              .doc('root')
              .collection('categories')
              .doc(categoryId)
              .collection('partnersList')
              .onSnapshot(
                partnersSnapshot => {
                  // After any partner changes, fetch all partners again
                  fetchAllPartners(dispatch, categories)
                },
                error => {
                  console.log(
                    `Error in partner listener for ${categoryId}:`,
                    error
                  )
                }
              )

            unsubscribeFunctions.push(partnerUnsubscribe)
          })

          // Initial fetch of all partners
          fetchAllPartners(dispatch, categories)
          dispatch(actions.setPartnerListenerAdded(true))
        },
        error => {
          console.log('Error getting partner categories:', error)
        }
      )

    unsubscribeFunctions.push(categoriesUnsubscribe)

    // Return cleanup function
    return () => {
      unsubscribeFunctions.forEach(unsub => {
        if (typeof unsub === 'function') {
          unsub()
        }
      })
    }
  }
}

// Helper function to fetch all partners
const fetchAllPartners = async (dispatch, categories) => {
  try {
    let partners = []
    let uniqueCountries = new Set()
    let uniqueRegions = new Set()

    // Get all categories
    const categoriesRef = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')

    // For each category, get all partners
    for (const category of categories) {
      const categoryId = category.key
      const categoryTitle = category.title

      const partnersSnapshot = await categoriesRef
        .doc(categoryId)
        .collection('partnersList')
        .get()

      if (partnersSnapshot.size > 0) {
        partnersSnapshot.docs.forEach(partner => {
          const partnerData = partner.data()

          // Extract country and region information
          let countries = []
          let regions = []

          // Check if geography exists and contains countries/regions
          if (partnerData.geography) {
            countries = partnerData.geography.countries || []
            regions = partnerData.geography.regions || []
            console.log(
              `Partner ${partner.id} regions (fetchAllPartners):`,
              regions
            )
          }

          // Add to unique sets
          countries.forEach(country => uniqueCountries.add(country))
          regions.forEach(region => uniqueRegions.add(region))

          partners.push({
            key: partner.id,
            title: partnerData.title || 'Unnamed Partner',
            category: categoryTitle,
            categoryId: categoryId,
            country: countries.length > 0 ? countries.join(', ') : 'N/A',
            region: regions.length > 0 ? regions.join(', ') : 'N/A',
            position: partnerData.position || 0,
            status: partnerData.status === 'active' ? 'Active' : 'Inactive',
            image: partnerData.imageUrl || '',
            affiliateLink: partnerData.affiliateLink || '',
            isGlobal: partnerData.isGlobal || false,
            metadata: partnerData.metadata || {},
          })
        })
      }
    }

    // Convert sets to arrays and sort
    const countries = Array.from(uniqueCountries).sort()
    const regions = Array.from(uniqueRegions).sort()

    // Update Redux store
    dispatch(actions.setPartners(partners))
    dispatch(actions.setPartnerCountries(countries))
    dispatch(actions.setPartnerRegions(regions))
  } catch (error) {
    console.error('Error fetching all partners:', error)
  }
}

// Function to add a new partner
export const AddPartner = async (partner, partnerImage) => {
  try {
    if (!partner.categoryId) {
      return { success: false, error: 'Category ID is required' }
    }

    const partnerRef = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(partner.categoryId)
      .collection('partnersList')
      .doc()

    // Upload image if provided
    let imageUrl = ''
    let imageThumbUrl = ''
    let imageFileName = ''

    if (partnerImage) {
      const { success, downloadUrl, message, profileImageName } =
        await UploadFile(partnerImage, 'partnersManagement/partners')

      if (!success) {
        return { success: false, error: message }
      }

      imageUrl = downloadUrl
      imageThumbUrl = downloadUrl
      imageFileName = profileImageName
    }

    // Prepare partner data
    const partnerData = {
      title: partner.title,
      position: parseInt(partner.position) || 0,
      status: partner.status
        ? partner.status === 'Active'
          ? 'active'
          : 'inactive'
        : 'active',
      imageUrl: imageUrl || partner.image || '',
      imageThumbUrl: imageThumbUrl || partner.image || '',
      imageFileName: imageFileName,
      affiliateLink: partner.affiliateLink || '',
      isGlobal: partner.isGlobal || false,
      createdAt: new Date(),
      updatedAt: new Date(),
    }

    // Add geography data if provided
    if (partner.country || partner.region) {
      // Ensure we're not creating nested arrays
      let countries = Array.isArray(partner.country)
        ? partner.country
        : [partner.country]
      let regions = Array.isArray(partner.region)
        ? partner.region
        : [partner.region]

      // Flatten arrays to ensure no nested arrays
      countries = countries.flat().filter(Boolean)
      regions = regions.flat().filter(Boolean)

      // Log for debugging
      console.log('Adding partner with regions:', regions)

      partnerData.geography = {
        countries: countries,
        regions: regions,
      }
    }

    await partnerRef.set(partnerData)

    return { success: true, id: partnerRef.id }
  } catch (error) {
    console.log('Error adding partner:', error)
    return { success: false, error }
  }
}

// Function to add a new partner category
export const AddPartnerCategory = async (category, categoryImage) => {
  try {
    const categoryRef = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc()

    // Upload image if provided
    let imageUrl = ''
    let imageThumbUrl = ''
    let imageFileName = ''

    if (categoryImage) {
      const { success, downloadUrl, message, profileImageName } =
        await UploadFile(categoryImage, 'partnersManagement/categories')

      if (!success) {
        return { success: false, error: message }
      }

      imageUrl = downloadUrl
      imageThumbUrl = downloadUrl
      imageFileName = profileImageName
    }

    // Prepare category data
    const categoryData = {
      title: category.title,
      position: parseInt(category.position) || 0,
      status: category.status
        ? category.status === 'Active'
          ? 'active'
          : 'inactive'
        : 'active',
      imageUrl: imageUrl,
      imageThumbUrl: imageThumbUrl,
      imageFileName: imageFileName,
      createdAt: new Date(),
      updatedAt: new Date(),
    }

    await categoryRef.set(categoryData)

    return { success: true, id: categoryRef.id }
  } catch (error) {
    console.log('Error adding partner category:', error)
    return { success: false, error }
  }
}

// Function to update a partner category
export const UpdatePartnerCategory = async (
  categoryId,
  category,
  categoryImage
) => {
  try {
    if (!categoryId) {
      return { success: false, error: 'Category ID is required' }
    }

    const categoryRef = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(categoryId)

    // Get current category data to check for existing image
    const categoryDoc = await categoryRef.get()
    const currentCategory = categoryDoc.data()

    // Upload new image if provided
    let imageUrl = category.image || currentCategory?.imageUrl || ''
    let imageThumbUrl = category.image || currentCategory?.imageThumbUrl || ''
    let imageFileName = currentCategory?.imageFileName || ''

    // Delete old image if a new one is provided
    if (categoryImage && imageFileName) {
      await DeleteFile(imageFileName, 'partnersManagement/categories')
    }

    // Upload new image
    if (categoryImage) {
      const { success, downloadUrl, message, profileImageName } =
        await UploadFile(categoryImage, 'partnersManagement/categories')

      if (!success) {
        return { success: false, error: message }
      }

      imageUrl = downloadUrl
      imageThumbUrl = downloadUrl
      imageFileName = profileImageName
    }

    // Prepare update data
    const updateData = {
      title: category.title,
      position: parseInt(category.position) || 0,
      status: category.status
        ? category.status === 'Active'
          ? 'active'
          : 'inactive'
        : currentCategory
        ? currentCategory.status
        : 'active',
      updatedAt: new Date(),
    }

    // Only update image if a new one was uploaded
    if (categoryImage) {
      updateData.imageUrl = imageUrl
      updateData.imageThumbUrl = imageThumbUrl
      updateData.imageFileName = imageFileName
    }

    await categoryRef.update(updateData)

    return { success: true }
  } catch (error) {
    console.log('Error updating partner category:', error)
    return { success: false, error }
  }
}

// Function to delete a partner category
export const DeletePartnerCategory = async categoryId => {
  try {
    if (!categoryId) {
      return { success: false, error: 'Category ID is required' }
    }

    // First check if there are any partners in this category
    const partnersSnapshot = await db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(categoryId)
      .collection('partnersList')
      .get()

    if (partnersSnapshot.size > 0) {
      return {
        success: false,
        error:
          'Cannot delete category with existing partners. Please delete all partners in this category first.',
      }
    }

    // If no partners, delete the category
    await db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(categoryId)
      .delete()

    return { success: true }
  } catch (error) {
    console.log('Error deleting partner category:', error)
    return { success: false, error }
  }
}

// Function to update a partner
export const UpdatePartner = async (partnerId, partner, partnerImage) => {
  try {
    if (!partner.categoryId) {
      return { success: false, error: 'Category ID is required' }
    }

    console.log('UpdatePartner called with:', { partnerId, partner })

    // Check if partnerId contains the categoryId
    let partnerDocId = partnerId
    let currentCategoryId = partner.categoryId

    // If partnerId includes a slash, it means it contains the categoryId
    if (partnerId.includes('/')) {
      // Get the partner's current category ID from the partner ID
      const partnerIdParts = partnerId.split('/')
      currentCategoryId = partnerIdParts[0]
      partnerDocId = partnerIdParts[1] || partnerId // Use the part after slash as doc ID
      console.log('Parsed partnerId:', { currentCategoryId, partnerDocId })
    }

    // First, verify the partner document exists in the current category
    const currentPartnerRef = db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(currentCategoryId)
      .collection('partnersList')
      .doc(partnerDocId)

    const currentPartnerDoc = await currentPartnerRef.get()

    if (!currentPartnerDoc.exists) {
      console.error('Partner document not found in original location:', {
        categoryId: currentCategoryId,
        partnerDocId,
      })

      // Try to find the partner in the target category (maybe it's already there)
      if (currentCategoryId !== partner.categoryId) {
        const targetPartnerRef = db
          .collection('partnersManagement')
          .doc('root')
          .collection('categories')
          .doc(partner.categoryId)
          .collection('partnersList')
          .doc(partnerDocId)

        const targetPartnerDoc = await targetPartnerRef.get()

        if (targetPartnerDoc.exists) {
          console.log(
            'Partner already exists in target category, updating there'
          )
          // Update in the target category
          await updatePartnerInCategory(
            targetPartnerRef,
            targetPartnerDoc.data(),
            partner,
            partnerImage
          )
          return { success: true }
        } else {
          return {
            success: false,
            error: 'Partner not found in either source or target category',
          }
        }
      }

      return { success: false, error: 'Partner not found' }
    }

    const currentPartner = currentPartnerDoc.data()

    // If the category has changed, we need to move the partner to the new category
    if (currentCategoryId !== partner.categoryId) {
      console.log(
        'Category changed from',
        currentCategoryId,
        'to',
        partner.categoryId
      )

      // Create the partner in the new category
      const newPartnerRef = db
        .collection('partnersManagement')
        .doc('root')
        .collection('categories')
        .doc(partner.categoryId)
        .collection('partnersList')
        .doc()

      // Upload new image if provided
      let imageUrl = partner.image || currentPartner?.imageUrl || ''
      let imageThumbUrl = partner.image || currentPartner?.imageThumbUrl || ''
      let imageFileName = currentPartner?.imageFileName || ''

      // Delete old image if a new one is provided
      if (partnerImage && imageFileName) {
        await DeleteFile(imageFileName, 'partnersManagement/partners')
      }

      // Upload new image
      if (partnerImage) {
        const { success, downloadUrl, message, profileImageName } =
          await UploadFile(partnerImage, 'partnersManagement/partners')

        if (!success) {
          return { success: false, error: message }
        }

        imageUrl = downloadUrl
        imageThumbUrl = downloadUrl
        imageFileName = profileImageName
      }

      // Prepare partner data for the new category
      const partnerData = {
        ...currentPartner,
        title: partner.title,
        position: parseInt(partner.position) || 0,
        status: partner.status
          ? partner.status === 'Active'
            ? 'active'
            : 'inactive'
          : currentPartner.status,
        imageUrl: imageUrl,
        imageThumbUrl: imageThumbUrl,
        imageFileName: imageFileName,
        affiliateLink:
          partner.affiliateLink || currentPartner.affiliateLink || '',
        isGlobal:
          partner.isGlobal !== undefined
            ? partner.isGlobal
            : currentPartner.isGlobal || false,
        updatedAt: new Date(),
      }

      // Update geography if country or region is provided
      if (partner.country || partner.region) {
        // Ensure we're not creating nested arrays
        let countries = Array.isArray(partner.country)
          ? partner.country
          : partner.country
          ? [partner.country]
          : []
        let regions = Array.isArray(partner.region)
          ? partner.region
          : partner.region
          ? [partner.region]
          : []

        // Handle string values that might be comma-separated
        if (
          countries.length === 1 &&
          typeof countries[0] === 'string' &&
          countries[0].includes(', ')
        ) {
          countries = countries[0].split(', ')
        }

        if (
          regions.length === 1 &&
          typeof regions[0] === 'string' &&
          regions[0].includes(', ')
        ) {
          regions = regions[0].split(', ')
        }

        // Flatten arrays to ensure no nested arrays
        countries = countries.flat().filter(Boolean)
        regions = regions.flat().filter(Boolean)

        partnerData.geography = {
          countries: countries,
          regions: regions,
        }
      }

      try {
        // Create the partner in the new category
        await newPartnerRef.set(partnerData)

        // Delete the partner from the old category
        await currentPartnerRef.delete()

        return { success: true, id: newPartnerRef.id }
      } catch (error) {
        console.error('Error moving partner to new category:', error)
        return {
          success: false,
          error: 'Failed to move partner to new category',
        }
      }
    } else {
      // If category hasn't changed, just update the partner in its current location
      console.log(
        'Updating partner in the same category:',
        partner.categoryId,
        'with docId:',
        partnerDocId
      )

      return await updatePartnerInCategory(
        currentPartnerRef,
        currentPartner,
        partner,
        partnerImage
      )
    }
  } catch (error) {
    console.log('Error updating partner:', error)
    return { success: false, error: error.message || 'Unknown error' }
  }
}

// Helper function to update a partner in a specific category
const updatePartnerInCategory = async (
  partnerRef,
  currentPartner,
  partner,
  partnerImage
) => {
  try {
    // Upload new image if provided
    let imageUrl = partner.image || currentPartner?.imageUrl || ''
    let imageThumbUrl = partner.image || currentPartner?.imageThumbUrl || ''
    let imageFileName = currentPartner?.imageFileName || ''

    // Delete old image if a new one is provided
    if (partnerImage && imageFileName) {
      await DeleteFile(imageFileName, 'partnersManagement/partners')
    }

    // Upload new image
    if (partnerImage) {
      const { success, downloadUrl, message, profileImageName } =
        await UploadFile(partnerImage, 'partnersManagement/partners')

      if (!success) {
        return { success: false, error: message }
      }

      imageUrl = downloadUrl
      imageThumbUrl = downloadUrl
      imageFileName = profileImageName
    }

    // Prepare update data
    const updateData = {
      title: partner.title,
      position: parseInt(partner.position) || 0,
      status: partner.status
        ? partner.status === 'Active'
          ? 'active'
          : 'inactive'
        : currentPartner
        ? currentPartner.status
        : 'active',
      updatedAt: new Date(),
    }

    console.log('Updating partner with data:', updateData)

    // Only update image if a new one was uploaded
    if (partnerImage) {
      updateData.imageUrl = imageUrl
      updateData.imageThumbUrl = imageThumbUrl
      updateData.imageFileName = imageFileName
    } else if (partner.image) {
      updateData.imageUrl = partner.image
      updateData.imageThumbUrl = partner.image
    }

    if (partner.affiliateLink) {
      updateData.affiliateLink = partner.affiliateLink
    }

    if (partner.isGlobal !== undefined) {
      updateData.isGlobal = partner.isGlobal
    }

    // Update geography if country or region is provided
    if (partner.country || partner.region) {
      // Ensure we're not creating nested arrays
      let countries = Array.isArray(partner.country)
        ? partner.country
        : partner.country
        ? [partner.country]
        : []
      let regions = Array.isArray(partner.region)
        ? partner.region
        : partner.region
        ? [partner.region]
        : []

      // Handle string values that might be comma-separated
      if (
        countries.length === 1 &&
        typeof countries[0] === 'string' &&
        countries[0].includes(', ')
      ) {
        countries = countries[0].split(', ')
      }

      if (
        regions.length === 1 &&
        typeof regions[0] === 'string' &&
        regions[0].includes(', ')
      ) {
        regions = regions[0].split(', ')
      }

      // Flatten arrays to ensure no nested arrays
      countries = countries.flat().filter(Boolean)
      regions = regions.flat().filter(Boolean)

      updateData.geography = {
        countries: countries,
        regions: regions,
      }
    }

    await partnerRef.update(updateData)
    return { success: true }
  } catch (error) {
    console.error('Error updating partner in category:', error)
    return { success: false, error: error.message || 'Unknown error' }
  }
}

// Function to delete a partner
export const DeletePartner = async (partnerId, categoryId) => {
  try {
    if (!categoryId) {
      return { success: false, error: 'Category ID is required' }
    }

    console.log('DeletePartner called with:', { partnerId, categoryId })

    // Handle case where partnerId might include categoryId
    let partnerDocId = partnerId
    if (partnerId.includes('/')) {
      const parts = partnerId.split('/')
      // If provided categoryId doesn't match the one in partnerId, use the one from partnerId
      if (parts[0] !== categoryId) {
        console.log(
          'CategoryId in partnerId differs from provided categoryId:',
          { inPartnerId: parts[0], provided: categoryId }
        )
        categoryId = parts[0]
      }
      partnerDocId = parts[1] || partnerId
    }

    console.log('Deleting partner:', { categoryId, partnerDocId })

    await db
      .collection('partnersManagement')
      .doc('root')
      .collection('categories')
      .doc(categoryId)
      .collection('partnersList')
      .doc(partnerDocId)
      .delete()

    return { success: true }
  } catch (error) {
    console.log('Error deleting partner:', error)
    return { success: false, error }
  }
}
