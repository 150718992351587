/* eslint-disable jsx-a11y/alt-text */
import CryptoJS from 'crypto-js'
import { GetCurrentUserToken } from '../services/user'
import { Upload } from 'antd'
import { secondsToShowAlert } from './constants'

export const SanitizeNumber = number => {
  let returnValue = number
  if (!number) return returnValue
  returnValue = returnValue.replace(/\D/g, '')
  return returnValue
}

export const AddIndex = arr => {
  return arr.map((item, index) => ({
    ...item,
    srNo: index + 1,
  }))
}

export const Sort = (array, key) => {
  const compare = (a, b) => {
    let comparison = 0
    let value1 = a[key]
    let value2 = b[key]
    if (typeof a[key] === 'string') {
      value1 = value1.toUpperCase()
      value2 = value2.toUpperCase()
    }
    if (value1 > value2) comparison = 1
    else if (value1 < value2) comparison = -1
    return comparison
  }
  return array.sort(compare)
}

export const departmentList = [1, 2, 3, 4, 5, 6]
export const staffDepartmentList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
]

export const Encrypt = text =>
  CryptoJS.AES.encrypt(text, process.env.REACT_APP_SECRET_KEY).toString()

export const GetAxiosHeaders = async () => {
  const token = await GetCurrentUserToken()
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }
}

export const validateProfileImage = (
  file,
  setImageError,
  setImage,
  setImageUrl,
  allowedImageSizeMb = 3
) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  const lessThanAllowedSize = file.size / 1024 <= 1024 * allowedImageSizeMb

  if (!isJpgOrPng) {
    setImageError('Invalid format, please upload JPEG or PNG')
  } else if (!lessThanAllowedSize) {
    setImageError('Maximum upload size is 3mb')
  } else {
    setImageError('')
  }

  if (isJpgOrPng && lessThanAllowedSize) {
    setImage(file)
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => {
      setImageUrl(fileReader.result)
    })
    fileReader.readAsDataURL(file)
  } else {
    setImage(null)
    setImageUrl(null)
  }

  return isJpgOrPng && lessThanAllowedSize ? true : Upload.LIST_IGNORE
}

export function removeDuplicates(array, key) {
  let lookup = new Set()
  return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]))
}

export const navConfig = [
  {
    index: 1,
    title: 'Dashboard',
    disabled: false,
    icon: <img src='images/dashboard.svg' alt=''></img>,
    url: '/Dashboard',
  },

  {
    index: 2,
    title: 'Hotel Management',
    disabled: false,
    icon: <img src='images/Departments.svg' alt=''></img>,
    url: '/HotelManagement',
  },
  {
    index: 3,
    title: 'Subscription',
    disabled: false,
    icon: <img src='images/Subscription.svg'></img>,
    url: '/Subscriptions',
  },
  {
    index: 4,
    title: 'Users',
    disabled: false,
    icon: <img src='images/GuestInfo.svg' alt=''></img>,
    url: '/Users',
  },
  {
    index: 5,
    title: 'Departments',
    disabled: false,
    icon: <img src='images/Services.svg' alt=''></img>,
    url: '/Departments',
  },
  {
    index: 11,
    title: 'Partner',
    disabled: false,
    icon: <img src='images/GuestInfo.svg' alt=''></img>,
    url: '/partner',
  },
  {
    index: 6,
    title: 'Location Types',
    disabled: false,
    icon: <img src='images/locationType.svg' alt=''></img>,
    url: '/LocationTypes',
  },
  {
    index: 7,
    title: 'Overall Feedback Questions',
    disabled: false,
    icon: <img src='images/overallfeedback.svg' alt=''></img>,
    url: '/OverAllFeedbackQuestions',
  },
  {
    index: 8,
    title: 'Settings',
    disabled: false,
    icon: <img src='images/settingsicon.svg' alt=''></img>,
    url: '/Settings?tab=1',
  },
  {
    index: 9,
    title: 'FAQ',
    disabled: false,
    icon: <img src='images/faq.png' alt=''></img>,
    url: '/AddFaq',
  },
  {
    index: 10,
    title: 'Translation Dictionary',
    disabled: false,
    icon: <img src='images/faq.png' alt=''></img>,
    url: '/TranslationDictionary',
  },
]

export const SetAutoClearProp = (
  Func,
  value,
  clearAfterSeconds = secondsToShowAlert
) => {
  Func(value)
  setTimeout(() => Func(''), clearAfterSeconds)
}

export function formatPrice(value) {
  const intlFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return intlFormatter.format(value).slice(1)
}

export const Ternary = (condition, trueValue, falseValue) =>
  condition ? trueValue : falseValue

export function isMissingPermissionError(error) {
  return error?.message?.startsWith('Missing or insufficient permissions.')
}

export function showStickyBar() {
  document.body.classList.add('toastOpen')
}

export function hideStickyBar() {
  document.body.classList.remove('toastOpen')
}

export const resType = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
}

export const modalTypeConstant = {
  AddHotelGroup: 'AddHotelGroup',
  EditHotelGroup: 'EditHotelGroup',
}

export function isValidUrl(str) {
  if (!str.toLowerCase().startsWith('http')) {
    str = 'http://' + str
  }

  if (str.indexOf('.') === -1) return false

  try {
    new URL(str)
  } catch {
    return false
  }

  return true
}

export const eIntergationType = {
  NONE: 'None',
  ANTLAB: 'ANTLAB',
}

export const ePmsType = {
  NONE: 'None',
  OPERA_OWS: 'OPERA OWS',
  OPERA_CLOUD: 'OPERA CLOUD',
  WINHMS: 'WINHMS',
}

export const ePmsIntergationType = {
  NONE: 'None',
  PMS_HOP: 'Pms with HOP',
  PMS: 'Only Pms',
}

export const sanitizeFieldPath = path => {
  return path.replace(/[~*/[\]]/g, '_')
}
